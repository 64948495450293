import React from 'react'
import BlockContent from '@sanity/block-content-to-react'
import client from '../../client'
import { Figure } from '../blocks/Figure'
import { FigureStamp } from '../blocks/FigureStamp'
import { Slider } from '../blocks/Slider'
import { Video } from '../blocks/Video'
import { Link } from '../blocks/Link'
import { Table } from '../blocks/Table'

let classString;

const serializers = {
	types: {
		block: ({ node, children }) => {
			switch (node.style) {
				case 'h1':
					return <h2 className="heading-1 spacing-2">{children}</h2>
				case 'h2':
					return <h2 className="heading-2 spacing-2 ">{children}</h2>
				case 'h3':
					return <h3 className="heading-3 spacing-2">{children}</h3>
				case 'h4':
					return <h4 className="heading-4 spacing-1">{children}</h4>
				case 'h5':
					return <h5 className="heading-5 spacing-1">{children}</h5>
				case 'h6':
					return <h6 className="heading-6 spacing-1">{children}</h6>
				case 'medium':
					return <h2 className="heading-md spacing-3">{children}</h2>
				case 'large':
					return <h2 className="heading-lg spacing-3">{children}</h2>
				default:
					return <p className={classString ? classString : ''}>{children}</p>
			}
		},
		figure: Figure,
		blogImage: Figure,
		figureStamp: FigureStamp,
		video: Video,
		slider: Slider,
		link: Link,
		table: Table,
	},
	marks: {
		color: ({ mark: { textColor }, children }) => (
			<div style={{ color: textColor }}>{children}</div>
		),
		center: ({ mark, children }) => (
			<span className={mark === 'center' && 'w-full inline-block text-center'}>
				{children}
			</span>
		),
		link: ({ mark, children }) => {
			const { href, link } = mark
			return (
				<a
					href={href || link}
					target="_blank"
					rel="noopener noreferrer"
					className="underline underline-offset"
				>
					{children}
				</a>
			)
		},
	},
	list: ({ children, type }) =>
		type === 'bullet' ? (
			<ul className="body-sm list-disc list-outside pl-[2%]">{children}</ul>
		) : (
			<ol className="body-sm list-decimal list-outside pl-[2%]">{children}</ol>
		),
}

export const PortableText = ({ blocks, className }) => {
	if (className) classString = className
	return (
		<BlockContent
			blocks={blocks}
			serializers={serializers}
			className={className}
			{...client}
		/>
	)
}
